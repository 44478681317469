import React, { useState, useEffect, useRef } from 'react'
import './expenseList.css'

import { useDispatch, useSelector } from "react-redux"
import { CSVLink } from 'react-csv';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FiChevronDown } from 'react-icons/fi';

import FilledRoundedButton from "./../../../components/Button/FilledRoundedButton"
import ButtonOulined from '../../../components/Button/ButtonOulined'
import SimpleTable from '../../../components/Table/SimpleTable'
import Tab from './../../../components/Tab';
import { Box, Popover, Button } from '@mui/material';
import Loading from '../../../components/Loading'
import { ADD_MESSAGE } from '../../../actions/types'
import { userTime } from '../../../utility/index'

import { getVendors } from "../../../actions/comments"
import { getExpense, deleteExpense, updateExpense, getClassification, importFile } from '../../../actions/expense'
import { getEmployees, getEmployeesContructor } from "../../../actions/employee"
import { getBranches } from "../../../actions/role"

import { useNavigate } from 'react-router-dom'

import Filter from './Filter'
import AddExpense from '../AddExpense';
import Comment from '../../Comment'
import Modal from '../../../components/Modal'
import axios from 'axios';



const tableHeader = [
    { name: "Action", size: "small" },
    { name: "Date", size: "medium" },
    { name: "Amount", size: "medium" },
    { name: "Amount1", size: "medium" },
    { name: "Payment Method", size: "medium" },
    { name: "Description", size: "medium" },
    { name: "Classification", size: "medium" },
    { name: "Classification1", size: "medium" },
    { name: "Paid By", size: "medium" },
    { name: "Paid To", size: "medium" },
    { name: "Branch", size: "medium" },
    // {name:"Account", size:"medium"},
]

const csv_headers = [
    { label: 'ID', key: 'id' },
    { label: 'Date', key: 'date' },
    { label: 'Paid To', key: 'paid_to' },
    { label: 'Paid By', key: 'paid_by' },
    { label: 'Branch', key: 'branch' },
    { label: 'Description', key: 'description' },
    { label: "Amount 1", key: "amount" },
    { label: "Amount 2", key: "amount1" },
    { label: "Classification 1", key: "classification" },
    { label: "Classification 2", key: "classification1" },
]

function ExpenseList() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()
    const importRef = useRef()

    const [anchorEl, setAnchorEl] = useState(null);
    const [openExport, setOpenExport] = useState(false);

    const role = useSelector((state) => state.auth.role)

    const [tabs, setTabs] = useState([{ name: 'Employee View', active: true }, { name: 'Company View', active: false }])
    const [branches, setBranches] = useState([])
    const [filterOpen, setOpen] = useState(false);
    const [expenses, setExpenses] = useState([])
    const [vendors, setVendors] = useState([])
    const [paidByUsers, setPaidByUsers] = useState([])
    const [isOpenExpenseAddModal, setIsOpenExpenseAddModal] = useState(false)
    const [isUpdate, setUpdate] = useState(false)
    const [openCommentBox, setOpenCommentBox] = useState(false)
    const [importIsOpen, setImportIsOpen] = useState(false)
    const [openCSVSuggestion, setOpenCSVSuggestion] = useState(false)
    const [activeTab, setActiveTab] = useState('employee')
    const [filterParameter, setFilterParameter] = useState({})
    const [employee, setEmployee] = useState([])
    const [exportData, setExportData] = useState([])
    const [markedItem, setMarkedItem] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [payType, setPayType] = useState("transaction")
    const [loading, setLoading] = useState(false)
    const [classification, setClassification] = useState([])
    const [totalAmount, setTotalAmount] = useState({})
    const [importCSVFile, setImportCSVFile] = useState()
    const [error, setError] = useState({})
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        !role.can_see_expense && setTabs(prevState => prevState.filter(tab => tab.name !== "Company View"))
    }, [role])

    useEffect(() => {
        if (payType === "transaction") {
            if (branches.length === 0) {
                dispatch(getBranches())
                    .then(response => {
                        if (response.status === 200) {
                            let x = response.data.map(branch => ({ name: branch.name, value: branch.id }))
                            setBranches(x)
                        }
                    })
            }
        } else {
            if (employee.length === 0) {
                dispatch(getEmployees())
                    .then(response => {
                        if (response.status === 200) {
                            console.log(response.data.data);
                            setEmployee(response.data)
                        }
                    })
            }
        }
        if (!isOpenExpenseAddModal) setUpdate(false)
    }, [isOpenExpenseAddModal, filterOpen, payType])

    useEffect(() => {
        setActiveTab(tabs[0].active ? 'employee' : 'company')
        setPage(1)
        setOpenCommentBox(false)
    }, [tabs])

    useEffect(() => {
        dispatch(getVendors({ options: true }))
            .then((response) => {
                if (response.status === 200) {
                    setVendors(response.data)
                }
            })

        if (classification.length === 0) {
            dispatch(getClassification())
                .then(response => {
                    if (response.status === 200) {
                        setClassification(response.data)
                    }
                })
        }
    }, [])

    useEffect(() => {
        dispatch(getEmployees())
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data);
                    setPaidByUsers(response.data)
                }
            })
    }, [])

    useEffect(() => {
        fetchExpenseData()
    }, [activeTab, page, filterParameter])


    const fetchExpenseData = (page_size = false, type = null) => {
        let query_param = { view: activeTab }
        if (page_size) query_param['page_size'] = page_size
        else query_param['page'] = page
        // let query_param = `&&page=${page}`
        for (const key in filterParameter) {
            if (key === "from" || key === "to")
                query_param[key] = new Date(filterParameter[key]).toUTCString()
            //     query_param = query_param + `${key}=${new Date(filterParameter[key]).toUTCString()}&&`
            // else query_param = query_param + `${key}=${filterParameter[key]}&&`
            else query_param[key] = filterParameter[key]
        }

        if (!page_size) setLoading(true)

        dispatch(getExpense(query_param))
            .then(response => {
                if (response.status === 200) {
                    if (page_size) {
                        if (type === "csv") {
                            setExportData(response.data.data)
                            ref.current.link.click()
                        }
                        else if (type === "pdf") generatePDF(response.data.data)
                    }
                    else {
                        setExpenses(response.data.data)
                        setTotalAmount(response.data.total)
                        setTotalPage(response.totalPage)
                    }
                }
                setLoading(false)
            })
    }

    const changePayType = (value) => {
        setPayType(value)
    }

    const handleUpdateExpense = (expense, update = false) => {
        if (update) {
            let newExpenses = expenses.map(e => e.id === expense.id ? expense : e);
            setExpenses(newExpenses)
        } else {
            setExpenses(prevState => [expense, ...prevState])
        }
    }

    const tabChangeHandler = (tab) => {
        const flag = tabs.map(t => t.name === tab.name ? { ...t, active: true } : { ...t, active: false })
        setTabs(flag)
    }

    const filterBodyHandler = () => {
        setOpen(prevState => !prevState)
        if (Object.keys(filterParameter).length) setFilterParameter({})
    }

    const filterDataHandler = (event) => {
        const name = event.target.name
        let value = event.target.value
        console.log(value);
        if (name === "search") {
            setFilterParameter({ [name]: value })
        } else setFilterParameter(prevState => ({ ...prevState, [name]: value }))
    }

    const handleUpdateExpenseData = (data) => {
        let paid_to;
        if (data.transaction_type === "salary") {
            paid_to = employee.filter(emp => emp.value == data.paid_to_salary)
        } else {
            paid_to = vendors.filter(vendor => vendor.value == data.paid_to)
        }
        data.paid_to = paid_to[0].name
        console.log(data.expense_receipt);
        data.expense_receipt = data.expense_receipt.length
        data.branch = branches.filter(branch => branch.value == data.branch)[0]['name']
        data.date = userTime(data.date, "DD MMM YYYY")
        setExpenses(prevState => prevState.map(exp => exp.id === data.id ? data : exp))
    }


    // Table
    const handleDeleteExpense = (exp) => {
        if (activeTab === "employee" || role.can_delete_expense || role.is_admin) {
            setLoading(true)
            dispatch(deleteExpense(exp.id, { view: activeTab }))
                .then((response) => {
                    if (response.status === 204) {
                        let newExpenses = expenses.filter(e => exp.id !== e.id)
                        setExpenses(newExpenses)
                        setLoading(false)
                    }
                })
        }
    }

    const editExpense = (exp) => {
        if (activeTab === "employee" || role.can_modify_expense || role.level <= 2) {
            setUpdate(exp.id)
            setIsOpenExpenseAddModal(true)
        }
    }

    const reviewExpense = (exp) => {
        if (role.can_review_expense) {
            dispatch(updateExpense(exp.id, { reviewed: !exp.reviewed }, { view: activeTab }))
                .then(response => {
                    if (response.status === 200) {
                        let newExpenses = expenses.map(e => e.id === exp.id ? { ...e, reviewed: !e.reviewed } : e)
                        setExpenses(newExpenses)
                    }
                })
        } else {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "You are not allowed to review expenses", status: 400 }
            })
        }
    }

    const onMark = (exp) => {
        const items = new Set(markedItem)
        if (items.has(exp)) items.delete(exp)
        else items.add(exp)
        setMarkedItem([...items])
    }

    const tableBody = (exp) => (
        <>
            {tableHeader.map((col, key, array) => 0 !== key &&
                <td style={{ cursor: "pointer" }} key={key} onClick={() => {
                    setOpenCommentBox(prevState => prevState === exp.id ? false : exp.id)
                    setExpenses(prevState => prevState.map(expense => expense.id === exp.id ? { ...expense, is_new_comment: false } : expense))
                }}>
                    {exp[col.name.replace(" ", "_").toLowerCase()]}
                </td>)}
        </>
    )

    const showTotal = () => (
        <>
            {tableHeader.map((col, key) =>
            (<td key={key}>
                {col.name === "Amount" ? <p className="highlight-text">{`Total Amount : ${totalAmount.amount__sum}`}</p> : ""}
            </td>)
            )}
        </>
    )

    const handlePage = (event, value) => {
        setPage(value)
    }

    // const generatePDF = tickets => {

    //     const doc = new jsPDF();

    //     const tableColumn = ["Id", "Date", "Paid To", "Paid By", "Branch", "Details", "Amount 1", "Amount 2", "Classification 1", "Classification 2"];
    //     const tableRows = [];

    //     tickets.map(expense => {
    //         const expense_row = [
    //             expense.id, expense.date, expense.paid_to, expense.paid_by, expense.branch, expense.description, expense.amount, expense.amount1, expense.classification, expense.classification1
    //         ];
    //         tableRows.push(expense_row)
    //     })

    //     doc.autoTable(tableColumn, tableRows, { startY: 20 });
    // doc.text("Expense Data.", 14, 15);
    // const date = Date().split(" ");
    // const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
    // doc.save(`report_${dateStr}.pdf`);
    // };

    // const importFileHandler = (event) => {

    //     if (event.target.files[0].type === "text/csv")
    //         setImportCSVFile(event.target.files[0])
    //     else {
    //         setError(prevState => ({ ...prevState, file: "Please select csv file" }))
    //         importRef.current.value = ""
    //     }
    // }






    const generatePDF = (tickets) => {
        const doc = new jsPDF({ orientation: "landscape" });

        const tableColumn = [
            "Date", "Amount", "Payment Method", "Description", "Classification", "Paid By", "Paid To", "Branch",
        ];

        const tableRows = [];

        tickets.forEach(expense => {
            tableRows.push([
                expense.date || "N/A",
                `$ ${(parseFloat(expense.amount) || 0).toFixed(2)}`,
                expense.payment_method || "N/A",
                expense.description || "N/A",
                expense.classification || "N/A",
                expense.paid_by || "N/A",
                expense.paid_to || "N/A",
                expense.branch || "N/A",
            ]);
        });

        doc.text("Expense Data", 14, 15);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
            styles: { fontSize: 7, overflow: 'linebreak' },  // Smaller font and enable line breaks
            headStyles: {
                fillColor: [0, 108, 176],
                textColor: [255, 255, 255],
                fontStyle: 'bold'
            },
            columnStyles: {

                0: { cellWidth: 30 }, // Date
                1: { cellWidth: 25 }, // Amount
                2: { cellWidth: 35 }, // Payment Method
                3: { cellWidth: 60 }, // Description
                4: { cellWidth: 40 }, // Classification
                5: { cellWidth: 30 }, // Paid By
                6: { cellWidth: 30 }, // Paid To
                7: { cellWidth: 25 }, // Branch
            },
            margin: { left: 10, right: 10 },
            theme: "grid"
        });

        const date = Date().split(" ");
        const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        doc.save(`report_${dateStr}.pdf`);
    };




    const importFileHandler = (event) => {
        // Log the event target files to see the selected file
        console.log("Selected file:", event.target.files[0]);

        // Check if the selected file is of type text/csv
        if (event.target.files[0].type === "text/csv") {
            // Set the selected CSV file to state
            setImportCSVFile(event.target.files[0]);

            // Read the contents of the CSV file
            const reader = new FileReader();
            reader.onload = (event) => {
                // Log the contents of the CSV file to the console
                console.log("CSV file contents:", event.target.result);
            };
            reader.readAsText(event.target.files[0]);
        } else {
            // Display error message if the selected file is not a CSV
            setError(prevState => ({ ...prevState, file: "Please select a CSV file" }));
            // Clear the file input value
            importRef.current.value = "";
        }
    }


    const handleImportData = () => {
        dispatch({
            type: ADD_MESSAGE,
            payload: { msg: "Importing Data. You will notify when it will done.", status: 1 }
        })
        dispatch(importFile(importCSVFile))
        // window.location.reload()
        importModalClose()
    }

    const importFileBody = () => {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <input className={`text-input medium-inp`} ref={importRef} type="file" id="file" name="file" accept=".csv"
                            onChange={importFileHandler} />
                        <FilledRoundedButton name="Import" size="small" onClickHandler={handleImportData} color="green" disabled={error.file && true} />
                    </div>
                    {error.file && <span className="error-message">{error.file}</span>}
                </div>
                <b onClick={() => setOpenCSVSuggestion(prevState => !prevState)} style={{ cursor: "pointer" }}>
                    Please maintain this header name and data format <FiChevronDown style={{ paddingTop: "3px", color: "blue" }} />
                </b>
                {openCSVSuggestion ? <table className="border-black">
                    <tr>
                        <td>payment_method</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>expense_type</td>
                        <td>C/E</td>
                    </tr>
                    <tr>
                        <td>branch</td>
                        <td>Branch Name</td>
                    </tr>
                    <tr>
                        <td>date</td>
                        <td>Date (16th August 2022)</td>
                    </tr>
                    <tr>
                        <td>amount</td>
                        <td>Number</td>
                    </tr>
                    <tr>
                        <td>amount1</td>
                        <td>Number</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>reviewed</td>
                        <td>TRUE/FALSE</td>
                    </tr>
                    <tr>
                        <td>transaction_type</td>
                        <td>transaction/salary</td>
                    </tr>
                    <tr>
                        <td>classification</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>classification1</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>paid_to</td>
                        <td>Valid Email</td>
                    </tr>
                    <tr>
                        <td>paid_to_salary</td>
                        <td>Valid Email</td>
                    </tr>
                    <tr>
                        <td>paid_by</td>
                        <td>Valid Email</td>
                    </tr>
                </table> : <></>}
            </div>
        )
    }

    const importModalClose = () => {
        setImportIsOpen(false)
        importRef.current.value = ""
        setImportCSVFile()
        setError({})
        // window.location.reload();
    }

    const deleteMultipleData = () => {
        if (activeTab === "employee" || role.can_delete_expense || role.is_admin) {
            setLoading(true)
            dispatch(deleteExpense(markedItem[0], { view: activeTab, ids: JSON.stringify(markedItem) }))
                .then((response) => {
                    let newExpenses = expenses.filter(e => !markedItem.includes(e.id))
                    setExpenses(newExpenses)
                    setLoading(false)
                })
        }
    }

    const confirmDeleteBody = () => (
        <Box>
            <p>Are you sure want to delete?</p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ButtonOulined name="Cancel" size="medium" onClickHandler={() => setConfirmDelete(false)} />
                <FilledRoundedButton name="Delete" color="red" size="medium" onClickHandler={() => {
                    deleteMultipleData()
                    setConfirmDelete(false)
                }} />
            </div>
        </Box>
    )

    return (
        <div>
            {isOpenExpenseAddModal && <AddExpense isOpen={isOpenExpenseAddModal} setIsOpen={setIsOpenExpenseAddModal} branches={branches} update={isUpdate} updateList={handleUpdateExpenseData}
                vendors={payType === "transaction" ? vendors : employee} updateExpenses={handleUpdateExpense} view={activeTab} changePayType={changePayType} payType={payType}
                classification={classification} />}
            <Modal header="Confirm Delete" body={confirmDeleteBody} isOpen={confirmDelete || false} modalHandler={() => setConfirmDelete(false)} />
            <Modal
                header={"Import Expense data from CSV"}
                body={importFileBody}
                isOpen={importIsOpen}
                modalHandler={importModalClose} />

            <div className="layout-title">
                <p>Expense</p>
                <div className="align-right">
                    <ButtonOulined name="Export" onClickHandler={(event) => {
                        setAnchorEl(event.currentTarget)
                        setOpenExport(true)
                    }} />
                    <Popover
                        open={openExport}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setAnchorEl(null)
                            setOpenExport(false)
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{ p: 2 }}
                    >
                        <Button variant="outlined" onClick={() => fetchExpenseData(totalPage * 15, "csv")}>CSV</Button>
                        <Button variant="outlined" onClick={() => fetchExpenseData(totalPage * 15, "pdf")}>PDF</Button>
                    </Popover>
                    <ButtonOulined name="Import" onClickHandler={() => setImportIsOpen(true)} />

                    <CSVLink ref={ref} headers={csv_headers} data={exportData} />
                    {(role.can_add_expense || activeTab === "employee") && <FilledRoundedButton name="Add Expense" color="green" size="small" onClickHandler={() => setIsOpenExpenseAddModal(true)} />}

                </div>
            </div>
            <div style={{ margin: "1rem" }}>
                <div className="align-left border border-radius">
                    <FilledRoundedButton name="Filters" size="medium" onClickHandler={filterBodyHandler} color="secondary" />
                </div>
                <Filter filterOpen={filterOpen} setOpen={filterBodyHandler} branches={branches} vendors={vendors} classification={classification}
                    filterParameter={filterParameter} filterDataHandler={filterDataHandler} paidByUsers={paidByUsers} />
                <Tab tabList={tabs} onClickHandler={tabChangeHandler} />

                <div className="align-left">
                    {markedItem.length ? <ButtonOulined name="Delete" size="small" onClickHandler={() => setConfirmDelete(true)} /> : <></>}
                    {/* <ButtonOulined name="Filters" size="small" onClickHandler={filterBodyHandler} /> */}
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 3, overflowX: "auto" }}>
                        {loading ? <Loading /> : <SimpleTable header={tableHeader} values={expenses} onDelete={handleDeleteExpense} markedItem={markedItem}
                            onEdit={editExpense} tableBody={tableBody} onCheck={reviewExpense} activeRow={openCommentBox} onMark={onMark}
                            page={page} handlePage={handlePage} totalPage={totalPage} total={Object.keys(filterParameter).length ? showTotal : null} />}
                    </div>
                    {openCommentBox && <div style={{ flex: 1 }}>
                        <Comment id={openCommentBox} query={`expense`} />
                    </div>}
                </div>
                {/* <h2>Total: {totalAmount.amount__sum}</h2> */}

                {/* {tabs[0].active ? <CompanyView expenses={expenses} /> : <EmployeeView expenses={expenses} />} */}
            </div>
        </div>
    )
}

export default ExpenseList