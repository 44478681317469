// import React, { useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { ReactApexChart } from 'apexcharts'
// import { useDispatch, useSelector } from "react-redux"

// import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi"

// import CashFlow from './CashFlow'
// import ExpenseBreakdown from './ExpenseBreakdown'
// import TextInput from '../../components/TextInput'

// import { getCashFlow, getOverdueList } from "../../actions/dashboard"
// import { getCustomROI, getROI } from '../../actions/transaction'


// function Dashboard() {
//     var today = new Date()
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const role = useSelector((state) => state.auth.role)

//     const [cashFlowData, setCashFlowData] = useState([])
//     const [cashFlowIncome, setCashFlowIncome] = useState([])
//     const [cashFlowExpense, setCashFlowExpense] = useState([])
//     const [cashFlowProfit, setCashFlowProfit] = useState([])
//     const [cashFlowCatagories, setCashFlowCatagories] = useState([])
//     const [overdue, setOverdue] = useState([])
//     const [page, setPage] = useState(1)
//     const [firstQuarterROI, setFirstQuarterROI] = useState('');
//     const [secondQuarterROI, setSecondQuarterROI] = useState('');
//     const [thirdQuarterROI, setThirdQuarterROI] = useState('');
//     const [year, setYear] = useState(new Date().getFullYear());
//     const [fromDate, setFromDate] = useState('');
//     const [toDate, setToDate] = useState('');
//     const [roi, setRoi] = useState('');

//     useEffect(() => {
//         if (role.level > 2)
//             navigate('/expense')
//         getCashFlowHandler();
//         getRateOfIncome(year)
//     }, [year])

//     useEffect(() => {
//         handleGetoverdueData()
//     }, [page])

//     const getRateOfIncome = (year) => {
//         dispatch(getROI(year))
//             .then(response => {
//                 if (response.status === 200) {
//                     setFirstQuarterROI(response.data.first_quarter_roi);
//                     setSecondQuarterROI(response.data.second_quarter_roi);
//                     setThirdQuarterROI(response.data.third_quarter_roi);
//                 }
//             })
//     }

//     const getROICustom = (from, to) => {
//         dispatch(getCustomROI(from, to))
//             .then((response) => {
//                 if (response.status === 200) {
//                     setRoi(response.data.roi)
//                 }
//             })
//     }

//     const handleChangeTo = (event) => {
//         setToDate(event.target.value)
//         getROICustom(fromDate, toDate)
//     }

//     const handleChangeFrom = (event) => {
//         setFromDate(event.target.value)
//     }
//     const handleGetoverdueData = () => {
//         dispatch(getOverdueList(page))
//             .then((response) => {
//                 if (response.status === 200) {
//                     console.log(response.data)
//                     setOverdue(response.data)
//                 } else setOverdue([])
//             })
//     }

//     const getCashFlowHandler = (year = null) => {
//         dispatch(getCashFlow(year))
//             .then(response => {
//                 if (response.status === 200) {
//                     setCashFlowData(response.data)
//                     console.log(response.data)

//                     let income = [], expense = [], profit = [], catagories = []
//                     response.data.map(cf => {
//                         catagories.push(cf.month)
//                         let i = (cf.amount_training || 0) + (cf.amount_support || 0)
//                         let e = (cf.amount_expense || 0)
//                         let p = i - e
//                         income.push(i)
//                         expense.push(e)
//                         profit.push(p)
//                     })

//                     setCashFlowIncome(income)
//                     setCashFlowExpense(expense)
//                     setCashFlowProfit(profit)
//                     setCashFlowCatagories(catagories)
//                 }
//             })
//     }

//     const cashFlowYearParameterHandeler = (event) => {
//         getCashFlowHandler(event.target.value)
//     }

//     const getYearlyROI = (param, year) => {
//         setYear(year + param);
//         getRateOfIncome(year)
//     }

//     return (
//         <div>
//             <div className="layout-title">
//                 <p>Dashboard</p>
//             </div>
//             {
//                 <>
//                     <div className="information-block" style={{ display: 'flex', justifyContent: "center" }}>
//                         <h3 style={{ marginRight: 4 }}>Return on Investment</h3>
//                         <h3><FiChevronsLeft style={{ marginRight: 4 }} onClick={() => getYearlyROI(-1, year)} /> {year} <FiChevronsRight style={{ marginLeft: 4 }} onClick={() => getYearlyROI(1, year)} /></h3>
//                         {/* <TextInput type='text' id='select-year' name='select-year' placeholder='Year' value={year} onChangeHandler={(event) => onChangeYear(event)}></TextInput> */}
//                     </div>
//                     <div className="information-block" style={{ display: 'flex', justifyContent: "space-between" }}>
//                         <h4>First Quarter: {firstQuarterROI}</h4>
//                         <h4>Second Quarter: {secondQuarterROI}</h4>
//                         <h4>Third Quarter: {thirdQuarterROI}</h4>
//                     </div>
//                     <div className="information-block" style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
//                         <div style={{ width: '50%' }}>
//                             <label>From</label><input type='date' style={{ margin: 4 }} onChange={(event) => handleChangeFrom(event)} />
//                             <label>To</label><input type='date' style={{ margin: 4 }} onChange={(event) => handleChangeTo(event)} />
//                             <button onClick={() => getROICustom(fromDate, toDate)}>Check</button>
//                         </div>
//                         <div style={{ width: '50%' }}>
//                             <h4>Return on Investment: {roi ? roi : 'Select the date range and click the Check button'}</h4>
//                         </div>
//                     </div>
//                     <div className="information-block" style={{ display: 'flex', justifyContent: "space-between" }}>
//                         {/* <div style={{ width: "25%" }}>
//                             <b className="information-block-header">Overdue List</b>
//                             <ul>
//                                 {overdue.map((data, key) => (
//                                     <li>
//                                         <p style={{ cursor: "pointer", color: "blue" }}
//                                             onClick={() => navigate(`/income/update/${data.income_job || data.income_software}/?type=${data.income_job ? "support" : "training"}`, { state: { type: "update" } })}>
//                                             {data.name || ""} - <span style={{ color: "black" }}>${data.target_amount} </span>
//                                         </p>
//                                     </li>
//                                 ))}
//                             </ul>
//                             {page > 1 && <p style={{ float: "left", paddingRight: "2px", color: "blue", cursor: "pointer" }}
//                                 onClick={() => setPage(prevState => prevState > 1 ? prevState - 1 : prevState)}><FiChevronsLeft style={{ paddingTop: "2px" }} /> Previous</p>}
//                             {overdue.length ? <p style={{ float: "right", paddingRight: "2px", color: "blue", cursor: "pointer", textAlign: 'center' }}
//                                 onClick={() => setPage(prevState => prevState + 1)}>Next <FiChevronsRight style={{ paddingTop: "2px" }} /></p> : <></>}
//                         </div> */}
//                         {/* <div style={{ borderLeft: "2px solid black" }}></div> */}
//                         <CashFlow cashFlowYearParameterHandeler={cashFlowYearParameterHandeler} cashFlowProfit={cashFlowProfit} cashFlowIncome={cashFlowIncome}
//                             cashFlowExpense={cashFlowExpense} cashFlowCatagories={cashFlowCatagories} />
//                     </div>
//                 </>}
//             <ExpenseBreakdown />
//         </div>
//     )
// }

// export default Dashboard








import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux"
import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi"
import ExpenseBreakdown from './ExpenseBreakdown'
import { fetchAllData } from '../Transaction/FetchAllData'
import Chart from "react-apexcharts";


function Dashboard() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [combinedData, setCombinedData] = useState([]);
    const [branches, setBranches] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [roiData, setRoiData] = useState({});
    const [roi, setRoi] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);


    const updateChartData = () => {
        if (selectedYear && roiData[selectedYear]) {
            let totalIncome = 0;
            let totalExpense = 0;

            // Iterate through each quarter of the selected year
            for (let quarter = 1; quarter <= 4; quarter++) {
                if (roiData[selectedYear][quarter]) {
                    totalIncome += parseFloat(roiData[selectedYear][quarter].income);
                    totalExpense += (roiData[selectedYear][quarter].expense);
                }
            }

            const profitLoss = (totalIncome - totalExpense).toFixed(2);

            setChartOptions({
                xaxis: {
                    categories: ['Income', 'Expense', 'Profit/Loss']
                },

                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    title: {
                        text: '$ (thousands)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " Dollars"
                        }
                    }
                }
            });

            setChartSeries([
                {
                    name: 'Financial Overview',
                    data: [totalIncome.toFixed(2), totalExpense.toFixed(2), profitLoss]
                },
            ]);
        }
    };

    useEffect(() => {
        updateChartData();
    }, [selectedYear, roiData]);






    // Define the calculateROI function
    const calculateROI = (combinedData) => {
        const roiByQuarter = {};

        combinedData.forEach((transaction) => {
            const date = new Date(transaction.date);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months
            const quarter = Math.floor((month - 1) / 3) + 1; // Assuming 4 quarters per year

            if (!roiByQuarter[year]) {
                roiByQuarter[year] = {};
            }

            if (!roiByQuarter[year][quarter]) {
                roiByQuarter[year][quarter] = { income: 0, expense: 0, roi: 0 };
            }

            if (transaction.type === 'Withdrawal') {
                const expenseAmount = transaction.amount;
                roiByQuarter[year][quarter].expense += expenseAmount;
            } else {
                const incomeAmount = parseFloat(transaction.amount);
                roiByQuarter[year][quarter].income += incomeAmount;
            }
        });

        // Log the calculated ROI data
        console.log('ROI by Quarter:', roiByQuarter);

        // Calculate ROI for each quarter
        Object.keys(roiByQuarter).forEach((year) => {
            Object.keys(roiByQuarter[year]).forEach((quarter) => {
                const { income, expense } = roiByQuarter[year][quarter];
                const cashInflow = income;
                const cashOutflow = expense;
                // const roi = cashOutflow !== 0 ? (cashInflow - cashOutflow) / cashOutflow * 100 : 0;
                const roi = cashOutflow !== 0 ? ((cashInflow - cashOutflow) - cashOutflow) / cashOutflow : 0;

                roiByQuarter[year][quarter].roi = roi.toFixed(2);
            });
        });

        return roiByQuarter;
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch all data
                const allData = await fetchAllData(dispatch);

                // Process the fetched data
                const { allTransactions, jobSupportData, enrolledData, otherIncomeData } = allData;
                const uniqueBranches = [...new Set(allTransactions.map(transaction => transaction.branch))];
                setBranches(uniqueBranches);

                const combinedData = [];

                // Process all transactions

                // allTransactions.forEach(transaction => {
                //     combinedData.push({
                //         date: (transaction.expense?.date || transaction?.income_software_training?.date) ? (transaction.expense?.date || transaction?.income_software_training?.date.split(' ')[0]) : null,
                //         amount: transaction.amount,
                //         type: transaction.type,
                //     });
                // });

                const excludedClassifications = [
                    "Equity - Owner's Contribution for Non 1099 Expenses - Rifat Halim",
                    "Refund - Income (Job Support)",
                    "Refund - Income (Software Training)"
                ];

                allTransactions.forEach(transaction => {
                    if (!excludedClassifications.includes(transaction.expense?.classification)) {
                        combinedData.push({
                            date: (transaction.expense?.date || transaction?.income_software_training?.date)
                                ? (transaction.expense?.date || transaction?.income_software_training?.date.split(' ')[0])
                                : null,
                            amount: transaction.amount,
                            type: transaction.type,
                        });
                    }

                    console.log("all transaction data:", transaction.expense?.classification);
                });


                // Process job support data
                jobSupportData.forEach(jobSupport => {

                    if (jobSupport.job_support_record_payment?.deposit && jobSupport.job_support_record_payment?.deposit !== '0.00') {
                        combinedData.push({
                            date: jobSupport.job_support_record_payment?.deposit_payment_date,
                            amount: jobSupport.job_support_record_payment?.deposit,
                            type: 'Deposit',
                        });
                    }
                });

                jobSupportData.forEach(jobSupport => {
                    if (jobSupport.job_support_record_payment?.first_payment_date) {
                        combinedData.push({
                            date: jobSupport.job_support_record_payment.first_payment_date,
                            amount: jobSupport.job_support_record_payment.first_payment_status,
                            type: 'Deposit',
                        });
                    }
                });

                jobSupportData.forEach(jobSupport => {
                    if (jobSupport.job_support_record_payment?.second_payment_date) {
                        combinedData.push({
                            date: jobSupport.job_support_record_payment.second_payment_date,
                            amount: jobSupport.job_support_record_payment.second_payment_status,
                            type: 'Deposit',
                        });
                    }
                });
                jobSupportData.forEach(jobSupport => {
                    if (jobSupport.job_support_record_payment?.third_payment_date) {
                        combinedData.push({
                            date: jobSupport.job_support_record_payment.third_payment_date,
                            amount: jobSupport.job_support_record_payment.third_payment_status,
                            type: 'Deposit',
                        });
                    }
                });
                jobSupportData.forEach(jobSupport => {
                    if (jobSupport.job_support_record_payment?.fourth_payment_date) {
                        combinedData.push({
                            date: jobSupport.job_support_record_payment.fourth_payment_date,
                            amount: jobSupport.job_support_record_payment.fourth_payment_status,
                            type: 'Deposit',
                        });
                    }
                });

                jobSupportData.forEach(jobSupport => {

                    if (jobSupport.new_job_support_payment.length > 0) {
                        jobSupport.new_job_support_payment.forEach(paymentInfo => {
                            if (paymentInfo.deposit) {
                                combinedData.push({
                                    date: paymentInfo.deposit_payment_date,
                                    amount: paymentInfo.deposit,
                                    type: 'Deposit',
                                });
                            }

                            if (paymentInfo.first_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.first_payment_date,
                                    amount: paymentInfo.first_payment_status,
                                    type: 'Deposit',
                                });
                            }

                            if (paymentInfo.second_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.second_payment_date,
                                    amount: paymentInfo.second_payment_status,
                                    type: 'Deposit',
                                });
                            }
                            if (paymentInfo.third_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.third_payment_date,
                                    amount: paymentInfo.third_payment_status,
                                    type: 'Deposit',
                                });
                            }
                            if (paymentInfo.fourth_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.fourth_payment_date,
                                    amount: paymentInfo.fourth_payment_status,
                                    type: 'Deposit',
                                });
                            }
                        });
                    }
                });


                // Process enrolled data
                enrolledData.forEach(enrolled => {

                    if (enrolled.payment_information?.enrollment_payment) {
                        combinedData.push({
                            date: enrolled.payment_information?.enrollment_payment_date,
                            amount: enrolled.payment_information?.enrollment_payment,
                            type: 'Deposit',
                        });
                    }

                    if (enrolled.payment_information?.first_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.first_payment_date,
                            amount: enrolled.payment_information.first_payment_status,
                            type: 'Deposit',
                        });
                    }

                    if (enrolled.payment_information?.second_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.second_payment_date,
                            amount: enrolled.payment_information.second_payment_status,
                            type: 'Deposit',
                        });
                    }

                    if (enrolled.payment_information?.third_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.third_payment_date,
                            amount: enrolled.payment_information.third_payment_status,
                            type: 'Deposit',
                        });
                    }

                    if (enrolled.payment_information?.fourth_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.fourth_payment_date,
                            amount: enrolled.payment_information.fourth_payment_status,
                            type: 'Deposit',
                        });
                    }

                    if (enrolled.new_payment_information.length > 0) {
                        enrolled.new_payment_information.forEach(paymentInfo => {
                            if (paymentInfo.enrollment_payment) {
                                combinedData.push({
                                    date: paymentInfo.enrollment_payment_date,
                                    amount: paymentInfo.enrollment_payment,
                                    type: 'Deposit',
                                });
                            }

                            if (paymentInfo.first_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.first_payment_date,
                                    amount: paymentInfo.first_payment_status,
                                    type: 'Deposit',
                                });
                            }

                            if (paymentInfo.second_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.second_payment_date,
                                    amount: paymentInfo.second_payment_status,
                                    type: 'Deposit',
                                });
                            }
                            if (paymentInfo.third_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.third_payment_date,
                                    amount: paymentInfo.third_payment_status,
                                    type: 'Deposit',
                                });
                            }
                            if (paymentInfo.fourth_payment_status) {
                                combinedData.push({
                                    date: paymentInfo.fourth_payment_date,
                                    amount: paymentInfo.fourth_payment_status,
                                    type: 'Deposit',
                                });
                            }
                        });
                    }
                });

                // Process Other income data
                otherIncomeData.forEach(otherIncome => {
                    if (otherIncome.paid_amount) {
                        combinedData.push({
                            date: otherIncome.date,
                            amount: otherIncome.paid_amount,
                            description: otherIncome.detail,
                            type: 'Deposit',
                            branch: otherIncome.location || 'Not Given'
                        });
                    }

                });

                // Update state with the combined data
                setCombinedData(combinedData);

                // Calculate ROI data
                const roiData = calculateROI(combinedData);
                setRoiData(roiData);

                // Extract years from ROI data
                const extractedYears = Object.keys(roiData);
                setYears(extractedYears);

                // Set default selected year
                if (extractedYears.length > 0) {
                    const sortedYears = extractedYears.sort((a, b) => b - a); // Sort years in descending order
                    setSelectedYear(sortedYears[0]); // Select the latest year
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    // Handle year change
    const handleYearChange = (delta) => {
        const index = years.indexOf(selectedYear);
        if (index !== -1 && index + delta >= 0 && index + delta < years.length) {
            setSelectedYear(years[index + delta]);
        }
    };



    // Event handler for when the user clicks the "Check" button
    const handleCheckButtonClick = () => {
        if (!startDate || !endDate) {
            alert('Please select both start and end dates.');
            return;
        }

        const filteredTransactions = combinedData.filter(transaction => {
            const transactionDate = new Date(transaction.date);
            return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
        });

        let totalIncome = 0;
        let totalExpense = 0;
        filteredTransactions.forEach(transaction => {
            if (transaction.type === 'Withdrawal') {
                totalExpense += transaction.amount;
            } else {
                totalIncome += parseFloat(transaction.amount);
            }
        });

        const roi = totalExpense !== 0 ? ((totalIncome - totalExpense) - totalExpense) / totalExpense : 0;

        // console.log('Total Income:', totalIncome);
        // console.log('Total Expense:', totalExpense);
        // console.log('ROI:', roi.toFixed(2) + '%');

        setRoi(roi.toFixed(2)); // Update ROI state
    };

    return (
        <div>
            <div className="layout-title">
                <p>Dashboard</p>
            </div>
            {
                <>
                    <div className="information-block" style={{ display: 'flex', justifyContent: "center" }}>
                        <h3 style={{ marginRight: 4 }}>Return on Investment</h3>
                        <h3>
                            <FiChevronsLeft style={{ marginRight: 4 }} onClick={() => handleYearChange(1)} />
                            {selectedYear}
                            <FiChevronsRight style={{ marginLeft: 4 }} onClick={() => handleYearChange(-1)} />
                        </h3>
                    </div>

                    {selectedYear && roiData[selectedYear] && (
                        <div className="information-block" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h4>First Quarter: {roiData[selectedYear]['1'] ? roiData[selectedYear]['1'].roi + '%' : 'N/A'}</h4>
                            <h4>Second Quarter: {roiData[selectedYear]['2'] ? roiData[selectedYear]['2'].roi + '%' : 'N/A'}</h4>
                            <h4>Third Quarter: {roiData[selectedYear]['3'] ? roiData[selectedYear]['3'].roi + '%' : 'N/A'}</h4>
                            <h4>Fourth Quarter: {roiData[selectedYear]['4'] ? roiData[selectedYear]['4'].roi + '%' : 'N/A'}</h4>
                        </div>
                    )}


                    <div className="information-block" style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                        <div style={{ width: '50%' }}>
                            <label>From</label><input type='date' style={{ margin: 4 }} onChange={(e) => setStartDate(e.target.value)} />
                            <label>To</label><input type='date' style={{ margin: 4 }} onChange={(e) => setEndDate(e.target.value)} />

                            <button onClick={handleCheckButtonClick}>Check</button>
                        </div>
                        <div style={{ width: '50%' }}>
                            <h4>Return on Investment: {roi ? roi + '%' : 'Select the date range and click the Check button'}</h4>
                        </div>
                    </div>
                </>
            }



            <div className="information-block">
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <b className="information-block-header">Cash Flow</b>

                </div>
                <div className="chart-container">
                    <div id="chart" style={{ width: "100%" }}>
                        <Chart
                            options={chartOptions}
                            series={chartSeries}
                            type="bar"
                            height={300}
                            width="100%" />
                    </div>
                </div>
            </div>


            <ExpenseBreakdown />

        </div>
    )
}

export default Dashboard