import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import FilledRoundedButton from '../../components/Button/FilledRoundedButton';
import TextInput from '../../components/TextInput';
import Loading from '../../components/Loading';
import Pagination from '@mui/material/Pagination';
import { CSVLink } from 'react-csv';
import Dashboard from '../Dashboard';
import { fetchAllData } from '../Transaction/FetchAllData';

const tableHeader = [
    { name: 'Date', size: 'medium' },
    { name: 'Description', size: 'medium' },
    { name: 'Type', size: 'medium' },
    { name: 'Amount', size: 'medium' },
    { name: 'Branch', size: 'medium' },
];

function IncomeStatement() {
    const dispatch = useDispatch();
    const [combinedData, setCombinedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [typeFilter, setTypeFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [branches, setBranches] = useState([]);
    const [branchFilter, setBranchFilter] = useState('');

    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
        setStartDate('');
        setEndDate('');
        setTypeFilter('');
        setBranchFilter('');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch all data
                const allData = await fetchAllData(dispatch);

                // Process the fetched data
                const { allTransactions, jobSupportData, enrolledData, otherIncomeData } = allData;
                const uniqueBranches = [...new Set(allTransactions.map(transaction => transaction.branch))];
                setBranches(uniqueBranches);

                const combinedData = [];

                // Process all transactions
                allTransactions.forEach(transaction => {
                    combinedData.push({
                        date: (transaction.expense?.date || transaction?.income_software_training?.date) ? (transaction.expense?.date || transaction?.income_software_training?.date.split(' ')[0]) : null,
                        amount: transaction.amount,
                        description: transaction.expense?.description || transaction?.income_software_training?.description,
                        classification: transaction.expense.classification,
                        type: transaction.type,
                        branch: transaction.branch
                    });
                });

                // // Process job support data
                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';

                //     // Check if deposit exists and is not "0.00"
                //     if (jobSupport.job_support_record_payment?.deposit && jobSupport.job_support_record_payment?.deposit !== "0.00") {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment?.deposit_payment_date,
                //             amount: jobSupport.job_support_record_payment?.deposit,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, Deposit Payment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });


                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
                //     if (jobSupport.job_support_record_payment?.first_payment_date) {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment.first_payment_date,
                //             amount: jobSupport.job_support_record_payment.first_payment_status,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });

                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
                //     if (jobSupport.job_support_record_payment?.second_payment_date) {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment.second_payment_date,
                //             amount: jobSupport.job_support_record_payment.second_payment_status,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });
                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
                //     if (jobSupport.job_support_record_payment?.third_payment_date) {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment.third_payment_date,
                //             amount: jobSupport.job_support_record_payment.third_payment_status,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });

                // jobSupportData.forEach(jobSupport => {

                //     if (jobSupport.new_job_support_payment.length > 0) {
                //         jobSupport.new_job_support_payment.forEach(paymentInfo => {
                //             if (paymentInfo.deposit) {
                //                 combinedData.push({
                //                     date: paymentInfo.deposit_payment_date,
                //                     amount: paymentInfo.deposit,
                //                     type: 'Deposit',
                //                 });
                //             }

                //             if (paymentInfo.first_payment_status) {
                //                 combinedData.push({
                //                     date: paymentInfo.first_payment_date,
                //                     amount: paymentInfo.first_payment_status,
                //                     type: 'Deposit',
                //                 });
                //             }

                //             if (paymentInfo.second_payment_status) {
                //                 combinedData.push({
                //                     date: paymentInfo.second_payment_date,
                //                     amount: paymentInfo.second_payment_status,
                //                     type: 'Deposit',
                //                 });
                //             }
                //             if (paymentInfo.third_payment_status) {
                //                 combinedData.push({
                //                     date: paymentInfo.third_payment_date,
                //                     amount: paymentInfo.third_payment_status,
                //                     type: 'Deposit',
                //                 });
                //             }
                //         });
                //     }
                // });

                jobSupportData.forEach(jobSupport => {
                    const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                    const id = jobSupport?.id;
                    const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';

                    if (jobSupport.job_support_record_payment) {
                        if (jobSupport.job_support_record_payment?.deposit && jobSupport.job_support_record_payment?.deposit !== "0.00") {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment?.deposit_payment_date,
                                amount: jobSupport.job_support_record_payment?.deposit,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Deposit Payment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.first_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.first_payment_date,
                                amount: jobSupport.job_support_record_payment.first_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.second_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.second_payment_date,
                                amount: jobSupport.job_support_record_payment.second_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.third_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.third_payment_date,
                                amount: jobSupport.job_support_record_payment.third_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.fourth_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.fourth_payment_date,
                                amount: jobSupport.job_support_record_payment.fourth_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Fourth Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }
                    }

                    if (jobSupport.new_job_support_payment.length > 0) {
                        jobSupport.new_job_support_payment.forEach(paymentInfo => {
                            if (paymentInfo.deposit && parseFloat(paymentInfo.deposit) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.deposit_payment_date,
                                    amount: paymentInfo.deposit,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New Deposit Payment (Enrolled Student`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }

                            if (paymentInfo.first_payment_status && parseFloat(paymentInfo.first_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.first_payment_date,
                                    amount: paymentInfo.first_payment_status,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New First Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }

                            if (paymentInfo.second_payment_status && parseFloat(paymentInfo.second_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.second_payment_date,
                                    amount: paymentInfo.second_payment_status,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New Second Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }
                            if (paymentInfo.third_payment_status && parseFloat(paymentInfo.third_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.third_payment_date,
                                    amount: paymentInfo.third_payment_status,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New Third Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }
                        });
                    }
                });


                // Process enrolled data
                enrolledData.forEach(enrolled => {
                    const name = enrolled?.name;
                    const id = enrolled?.id;
                    const enrolledProgram = enrolled?.enrolled_program || 'Not Given';

                    if (enrolled.payment_information?.enrollment_payment) {
                        combinedData.push({
                            date: enrolled.payment_information?.enrollment_payment_date,
                            amount: enrolled.payment_information?.enrollment_payment,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Enrollment Payment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.first_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.first_payment_date,
                            amount: enrolled.payment_information.first_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.second_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.second_payment_date,
                            amount: enrolled.payment_information.second_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.third_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.third_payment_date,
                            amount: enrolled.payment_information.third_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.fourth_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.fourth_payment_date,
                            amount: enrolled.payment_information.fourth_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Fourth Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }


                    if (enrolled.new_payment_information.length > 0) {
                        enrolled.new_payment_information.forEach(paymentInfo => {
                            if (paymentInfo.enrollment_payment && parseFloat(paymentInfo.enrollment_payment) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.enrollment_payment_date,
                                    amount: paymentInfo.enrollment_payment,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Enrollment Payment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }

                            if (paymentInfo.first_payment_status && parseFloat(paymentInfo.first_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.first_payment_date,
                                    amount: paymentInfo.first_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New First Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }

                            if (paymentInfo.second_payment_status && parseFloat(paymentInfo.second_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.second_payment_date,
                                    amount: paymentInfo.second_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Second Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }
                            if (paymentInfo.third_payment_status && parseFloat(paymentInfo.third_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.third_payment_date,
                                    amount: paymentInfo.third_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Third Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }
                            if (paymentInfo.fourth_payment_status && parseFloat(paymentInfo.fourth_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.fourth_payment_date,
                                    amount: paymentInfo.fourth_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Fourth Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }
                        });
                    }



                });


                // Process Other income data
                otherIncomeData.forEach(otherIncome => {
                    if (otherIncome.paid_amount) {
                        combinedData.push({
                            date: otherIncome.date,
                            amount: otherIncome.paid_amount,
                            description: otherIncome.detail,
                            type: 'Deposit',
                            branch: otherIncome.location || 'Not Given'
                        });
                    }

                });

                // Update state with the combined data sorted by date (latest to oldest)
                setCombinedData(
                    combinedData.sort((a, b) => {
                        const dateA = a.date ? new Date(a.date) : 0;
                        const dateB = b.date ? new Date(b.date) : 0;
                        return dateB - dateA;
                    })
                );
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    // Memoized filtered data
    const filteredData = useMemo(() => {
        let filtered = combinedData;

        if (startDate && endDate) {
            filtered = filtered.filter(item => {
                const itemDate = new Date(item.date);
                const start = new Date(startDate);
                const end = new Date(endDate);
                return itemDate >= start && itemDate <= end;
            });
        }

        if (typeFilter) {
            filtered = filtered.filter(item => item.type === typeFilter);
        }

        if (branchFilter) {
            filtered = filtered.filter(item => item.branch === branchFilter);
        }

        return filtered;
    }, [combinedData, startDate, endDate, typeFilter, branchFilter]);


    const expenseClassification = {
        "50% Meal": ["50% meal"],
        "100% Meal": ["100% meal"],
        "Accounting Fees": ["accounting fees"],
        "Advertisement & Promotion": ["advertisement"],
        "Bank Service Charges": ["bank service charges"],
        "Computer-Hardware": ["computer - hardware"],
        "Computer-Hosting": ["computer - hosting"],
        "Computer-Internet": ["computer - internet"],
        "Computer-Software": ["computer - software"],
        "Consultancy Fee for Job Support": ["consultancy fee for job support"],
        "Consultant and Non 1099 Expense": ["consultant and non 1099 expense"],
        "Consultant and 1099 Expense": ["consultant and 1099 expense"],
        "Corporate Insurance": ["corporate insurance"],
        "Corporate Tax": ["corporate tax"],
        "Depreciation Expense": ["depreciation expense"],
        "Dues & Subscriptions": ["dues & subscriptions"],
        "Education & Training": ["education & training"],
        "Gain on Sale of Asset": ["gain on sale of asset"],
        "Income Tax Liability": ["income tax liability"],
        "Income Tax": ["income tax"],
        "Insurance - Disability": ["insurance - disability"],
        "Insurance - Unemployment": ["insurance - unemployment"],
        "Insurance - General Liability": ["insurance - general liability"],
        "Insurance - Vehicles": ["insurance - vehicles"],
        "Interest Expense": ["interest expense"],
        "License & Fees": ["license & fees"],
        "Meals and Entertainment": ["meals and entertainment"],
        "Office Expense": ["office expense"],
        "Office Supplies": ["office supplies"],
        "Owner's contribution for Non 1099 Expense": ["owner's contribution"],
        "Owner's Distribution for Non 1099 Expenses": ["owner's distribution"],
        "Owner's Profit Distribution - Mohammad Farid Uddin": ["owner's profit distribution - mohammad farid uddin"],
        "Owner's Profit Distribution - Rifat Halim": ["owner's profit distribution - rifat halim"],
        "Owner's Profit Distribution": ["owner's profit distribution"],
        "Owner's Salary (W2)": ["owner's salary", "w2"],
        "Postal Fee": ["postal fee"],
        "Payroll Tax": ["payroll tax"],
        "Professional fee for Tax services": ["professional fee for tax services"],
        "Professional Fees": ["professional fees"],
        "Rent Expense": ["rent expense"],
        "Repairs & Maintenance": ["repairs & maintenance"],
        "Taxes - Corporate Tax": ["taxes - corporate tax"],
        "Travel Expense": ["travel expense"],
        "Telephone - Wireless": ["telephone - wireless"],
        "Utilities": ["utilities"],
        "Wells Fargo Cash Back": ["wells fargo cash back"]
    };


    const getCategory = (description, type, classification) => {
        // Normalize classification and description to lowercase for case-insensitive comparison
        const normalizedDescription = description.toLowerCase();
        const normalizedClassification = classification ? classification.toLowerCase() : "";

        // For expenses (Withdrawal), use the classification field
        if (type === "Withdrawal") {
            // Check if classification contains "Equity"
            if (normalizedClassification.includes("equity - owner's contribution for non 1099 expense")) {
                return "Equity";
            }
            if (normalizedClassification.includes("refund - income")) {
                return "Refund";
            }
            if (normalizedClassification === "1099 expense") {
                return "1099 Expense";
            }

            // Otherwise, check the classification against predefined categories
            for (const [category, keywords] of Object.entries(expenseClassification)) {
                if (keywords.some((keyword) => normalizedClassification.includes(keyword))) {
                    return category;
                }
            }
            return "Other Expense"; // Default for unclassified expenses
        }

        // For income (Deposit), retain description-based categorization
        if (type === "Deposit") {
            if (normalizedDescription.includes("job support")) return "Job Support";
            if (normalizedDescription.includes("accessibility testing")) return "Accessibility Testing";
            if (normalizedDescription.includes("software testing with selenium")) return "Software Testing with Selenium";
            if (normalizedDescription.includes("cyber security for professional")) return "Cyber Security for Professional";
            if (normalizedDescription.includes("data analysis with python")) return "Data Analysis with Python";
            if (normalizedDescription.includes("cost of goods sold")) return "Cost of Goods Sold";
            if (normalizedDescription.includes("basic computing for it help desk support")) return "Basic Computing for IT Help Desk Support";
            if (normalizedDescription.includes("bi developer with power bi & sql server")) return "BI Developer with Power BI & SQL Server";
            if (normalizedDescription.includes("net training")) return ".NET Training";

            return "Other Income"; // Default for unclassified income
        }

        return "Uncategorized"; // Default for uncategorized items
    };


    const { totalIncome, totalExpense, totalCOGS, groupedIncome, groupedExpense, otherIncomeDetails, otherExpenseDetails, equityAndRefundDetails, totalEquity,
        totalRefund } = useMemo(() => {
            let totalIncome = 0;
            let totalExpense = 0;
            let totalCOGS = 0;
            let totalEquity = 0;
            let totalRefund = 0;
            const groupedIncome = {};
            const groupedExpense = {};
            const otherIncomeDetails = [];
            const otherExpenseDetails = [];
            const equityAndRefundDetails = [];

            filteredData.forEach((item) => {
                const amount = parseFloat(item.amount);
                if (isNaN(amount)) return;

                const category = getCategory(item.description, item.type, item.classification);

                if (category === "Equity" || category === "Refund") {
                    equityAndRefundDetails.push({ ...item, category });

                    if (category === "Equity") {
                        totalEquity += amount;
                    } else if (category === "Refund") {
                        totalRefund += amount;
                    }

                    return;
                }

                if (item.type === "Deposit") {
                    totalIncome += amount;

                    if (category === "Other Income") {
                        otherIncomeDetails.push(item);
                    }

                    groupedIncome[category] = (groupedIncome[category] || 0) + amount;
                } else if (item.type === "Withdrawal") {
                    totalExpense += amount;

                    if (category === "Cost of Goods Sold") {
                        totalCOGS += amount; // Add to COGS if it's a withdrawal for COGS
                    }

                    if (category === "Other Expense") {
                        otherExpenseDetails.push(item);
                    }

                    groupedExpense[category] = (groupedExpense[category] || 0) + amount;
                }
            });

            return {
                totalIncome,
                totalExpense,
                totalCOGS,
                groupedIncome,
                groupedExpense,
                otherIncomeDetails,
                otherExpenseDetails,
                equityAndRefundDetails,
                totalEquity,
                totalRefund,
            };
        }, [filteredData]);


    // Calculate Gross Profit and Profit/Loss
    const grossProfit = totalIncome - totalCOGS;
    const profitLoss = grossProfit - totalExpense;

    let result = '';
    if (totalIncome > totalExpense) {
        result = `Profit: $${profitLoss.toFixed(2)}`;
    } else if (totalExpense > totalIncome) {
        result = `Loss: $${Math.abs(profitLoss).toFixed(2)}`;
    } else {
        result = 'No Profit/Loss (Income equals Expense)';
    }


    // Pagination
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    // Function to handle CSV data
    const handleCSVData = () => {
        return filteredData.map(item => ({
            Date: item.date || 'Not Given',
            Description: item.description,
            Type: item.type,
            Amount: item.amount,
            Branch: item.branch
        }));
    };

    const selectStyle = {
        backgroundColor: '#EAEFF4',
        boxSizing: 'border-box',
        border: '2px solid #CDCDCD',
        borderRadius: '5px',
        padding: '0.5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        width: '20rem'
    };

    return (
        <div>

            <div className="layout-title">
                <p>Transaction Summary</p>
                <div className="align-right">
                    {/* <CSVLink
                        data={handleCSVData()}
                        filename={"transaction_data.csv"}
                        style={{
                            border: '1px solid #1976d2',
                            color: '#1976d2',
                            padding: '10px 20px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '15px',
                            margin: '4px 2px',
                            cursor: 'pointer',
                            borderRadius: '15px',
                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#1976d2';
                            e.target.style.color = '#fff';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = '#1976d2';
                        }}
                    >
                        Export CSV
                    </CSVLink> */}
                </div>
            </div>

            <div>
                <div className="align-left border border-radius">
                    <FilledRoundedButton name="Filters" size="medium" onClickHandler={toggleFilter} color="secondary" />
                </div>
                {filterOpen && (
                    <div>
                        <div className="filter-body-left">
                            <TextInput id="from" name="from" placeholder="From" type="date" size="small" onChangeHandler={(e) => setStartDate(e.target.value)} value={startDate} />
                            <TextInput id="to" name="to" placeholder="To" type="date" size="small" onChangeHandler={(e) => setEndDate(e.target.value)} value={endDate} />

                            <div>
                                <label htmlFor="typeFilter">Type:</label>
                                <select
                                    id="typeFilter"
                                    value={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                    style={selectStyle}
                                >
                                    <option value="">All</option>
                                    <option value="Deposit">Deposit</option>
                                    <option value="Withdrawal">Withdrawal</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="branchFilter">Branch:</label>
                                <select
                                    id="branchFilter"
                                    value={branchFilter}
                                    onChange={(e) => setBranchFilter(e.target.value)}
                                    style={selectStyle}
                                >
                                    <option value="">All</option>
                                    {branches.map((branch, index) => (
                                        <option key={index} value={branch}>{branch}</option>
                                    ))}
                                    <option value="Online">Online</option>
                                </select>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {loading ? (
                <Loading />
            ) : (
                <div>
                    {/* <div className="table-body">
                        <table style={{ width: '100%' }}>
                            <thead className="thead">
                                <tr>
                                    {tableHeader.map((th, i) => (
                                        <th className={`${th.size}-header`} key={i}>{th.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item.date || 'Not Given'}</td>
                                        <td>{item.description}</td>
                                        <td>{item.type}</td>
                                        <td>${item.amount}</td>
                                        <td>{item.branch}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        style={{ marginRight: '1rem', display: 'flex', justifyContent: 'right' }}
                        color="primary"
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        page={currentPage}
                        onChange={handlePageChange}
                    /> */}


                    <div className="summary-section" style={{ padding: '1rem', borderTop: '1px solid #ddd' }}>
                        {/* Total Income and Total Expense */}
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '2rem', marginBottom: '1rem' }}>
                            <div>
                                <h4>Total Income:</h4>
                                <p style={{ color: 'green', fontWeight: 'bold' }}>${totalIncome.toFixed(2)}</p>
                            </div>
                            <div>
                                <h4>Total Expense:</h4>
                                <p style={{ color: 'red', fontWeight: 'bold' }}>${totalExpense.toFixed(2)}</p>
                            </div>
                        </div>
                        <hr />
                        {/* Gross Profit and Profit/Loss Calculation */}
                        <div style={{ marginBottom: '1rem' }}>
                            <h4>Gross Profit: <span style={{ fontWeight: 'bold' }}>${(totalIncome - totalCOGS).toFixed(2)}</span></h4>

                            <h4>Profit/Loss: <span style={{ fontWeight: 'bold', color: profitLoss >= 0 ? 'green' : 'red' }}>{profitLoss >= 0 ? 'Profit' : 'Loss'}: ${Math.abs(profitLoss).toFixed(2)}</span></h4>
                        </div>
                        <hr />
                        {/* Total Cost of Goods Sold */}
                        <div style={{ marginBottom: '1rem' }}>
                            <h4>Total Cost of Goods Sold (COGS): <span style={{ fontWeight: 'bold' }}>${totalCOGS.toFixed(2)}</span></h4>
                        </div>
                        <hr />
                        {/* Income Details Grouped by Category */}
                        <div>
                            <h4>Income Details (Grouped by Category):</h4>
                            <ul>
                                {Object.entries(groupedIncome).map(([category, amount], index) => (
                                    <li key={index} style={{ marginBottom: '0.5rem' }}>
                                        <strong>{category}</strong>: ${amount.toFixed(2)}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Other Income Details */}
                        {otherIncomeDetails.length > 0 && (
                            <div style={{ marginTop: '1rem' }}>
                                <h4>Other Income Details:</h4>
                                <ul>
                                    {otherIncomeDetails.map((item, index) => {
                                        const amount = parseFloat(item.amount);
                                        return (
                                            <li key={index}>
                                                {item.description} - ${isNaN(amount) ? 'Invalid amount' : amount.toFixed(2)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        <hr />
                        {/* Expense Details */}
                        <section style={{ marginBottom: '1.5rem' }}>
                            <h4>Expense Details (Grouped by Category)</h4>
                            <ul>
                                {Object.entries(groupedExpense).map(([category, amount], index) => (
                                    <li key={index} style={{ marginBottom: '0.5rem' }}>
                                        <strong>{category}</strong>: ${amount.toFixed(2)}
                                    </li>
                                ))}
                            </ul>
                        </section>

                        <section style={{ marginBottom: '1.5rem' }}>
                            <h4>Equity and Refund Transactions</h4>
                            <ul>
                                {equityAndRefundDetails.map((item, index) => (
                                    <li key={index} style={{ marginBottom: '0.5rem' }}>
                                        <strong>{item.category} : </strong>  -
                                        {item.description} -
                                        <strong> Amount:</strong> ${parseFloat(item.amount).toFixed(2)}
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                <li style={{ marginBottom: '0.5rem' }}><strong>Total Equity:</strong> ${totalEquity.toFixed(2)}</li>
                                <li> <strong>Total Refund:</strong> ${totalRefund.toFixed(2)}</li>
                            </ul>
                        </section>


                        {otherExpenseDetails.length > 0 && (
                            <div style={{ marginTop: '1rem' }}>
                                <h4>Other Expense Details:</h4>
                                <ul>
                                    {otherExpenseDetails.map((item, index) => {
                                        const amount = parseFloat(item.amount);
                                        return (
                                            <li key={index}>
                                                {item.classification} - ${isNaN(amount) ? 'Invalid amount' : amount.toFixed(2)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>


                </div>
            )}
        </div>
    );
}

export default IncomeStatement;



